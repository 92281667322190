import { DefaultTheme } from 'styled-components';

export const defaultTheme: DefaultTheme = {
  name: 'default',
  isWebView: false,
  tabBarHeight: 60,
  palette: {
    primary: {
      background: '#fff',
      border: '#f1f3f7',
      labels: '#212c3a',
    },
    secondary: {
      background: '#f6f7fa',
      border: '#eaebf2',
      labels: '#8d94ad',
    },
    tertiary: {
      background: '#fff5f1',
      labels: '#b1b8c6',
    },
    quartenary: {
      background: '#f6f7fa',
    },
    system: {
      deepBlue: '#4264fd',
      blue: '#4c88f9',
      lightBlue: '#dae8ff',
      green: '#3db460',
      red: '#ff586d',
      yellow: '#ffb900',
      white: '#fff',
      blueToWhite: '#4c88f9',
    },
    brand: {
      main: '#ff4f08',
      vip2: '#ff7a45',
      premium2: '#8c9eff',
    },
    package: {
      basis: '#696969',
    },
    overlay: {
      40: 'rgba(0, 0, 0, 0.4)',
    },
    gradient: {
      main: 'linear-gradient(135deg, #fe5722 0%, #fe5631 52.91%, #fe5449 83.29%, #fe6168 100%)',
    },
  },
};
